<template>
  <div class="main-card mb-3 card pa-0 ma-0" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
    <div class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-title card-header-tab card-header">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('erp.lang_viewOrders') }} {{ this.order != null ? '(' + this.order.reference + ')' : "" }}
        </div>

        <div class="btn-actions-pane-right actions-icon-btn">
          <v-btn class="" color="warning" icon @click="dialog = true">
            <v-icon>email</v-icon>
          </v-btn>
        </div>
      </div>
      <v-card-text class="pa-0 ma-0">
        <v-data-table :headers="this.headers" :items="this.items" :loading="this.loading">
          <template v-slot:item.ean="{item}">
            <strong>{{ item.ean }}</strong>
          </template>
          <template v-slot:item.wareName="{item}">
            <strong class="text-primary">{{ item.wareName }}</strong>
          </template>
          <template v-slot:item.currentQTY="{item}">
            <v-chip :color="getColor(item.currentQTY , item.minBestand , item.currentQTY)"
                    class="mr-2"
                    small>
              {{ item.currentQTY }}
            </v-chip>
          </template>

          <template v-slot:item.orderedQTY="{item}">
            <strong>{{ item.orderedQTY }}</strong>
          </template>

          <template v-slot:item.vpe="{item}">
            <strong>
              {{
                (Number(item.orderedQTY / item.unitPerPackage) &&
                    (item.orderedQTY / item.unitPerPackage % 1
                        === 0)) ? item.orderedQTY / item.unitPerPackage :
                    ((item.orderedQTY / item.unitPerPackage == 0) ? 0
                        : parseInt(item.orderedQTY / item.unitPerPackage) + 1)
              }}
            </strong>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-text>
        <v-dialog v-model="dialog" max-width="600px" persistent>
          <v-form ref="emailForm" lazy-validation>
            <div class="card" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
              <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
                {{ $t('generic.lang_email') }}
              </div>

              <div class="card-body">
                <v-row>
                  <v-col class="pb-0 mb-0" cols="12">

                    <v-combobox
                        v-model="form.emails" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :filter="filter"
                        :hide-no-data="!search"
                        :items="[]"
                        :rules="[v => !!v]"
                        :search-input.sync="search"
                        autofocus
                        clearable
                        dense
                        hide-selected
                        :label="$t('generic.lang_reception')"
                        multiple
                        outlined
                        small-chips
                        @focus="showTouchKeyboard"
                    >
                      <template v-slot:no-data>
                        <v-list-item class="transparent">
                          <v-chip
                              :color="`primary lighten-3`"
                              label
                              small
                          >
                            {{ search }}
                          </v-chip>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                            v-if="item === Object(item)"
                            v-bind="attrs"
                            :color="`${item.color} lighten-3`"
                            :input-value="selected"
                            label
                            small
                        >
          <span class="pr-2">
            {{ item.text }}
          </span>
                          <v-icon
                              small
                              @click="parent.selectItem(item)"
                          >close
                          </v-icon>
                        </v-chip>
                      </template>

                    </v-combobox>
                  </v-col>

                  <v-col class="pt-0 mt-0 pb-0 mb-0" cols="12">
                    <v-text-field v-model="form.subject" :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v]"
                                  clearable dense :label="$t('settings.lang_mail_subject')" outlined @focus="showTouchKeyboard"/>
                  </v-col>

                  <v-col class="pt-0 mt-0 pb-0 mb-0" cols="12" sm="12">
                    <v-row class="pt-0 mt-0 pb-0 mb-0">
                      <v-col class="pt-0 mt-0 pb-0 mb-0" cols="6">
                        <v-checkbox v-model="form.pdf" :value="true"
                                    class="pt-0 mt-0 pb-0 mb-0"
                                    :label="$t('generic.lang_attachPdf')"/>
                      </v-col>

                      <v-col class="pt-0 mt-0 pb-0 mb-0" cols="6">
                        <v-checkbox v-model="form.excel" :value="true"
                                    class="pt-0 mt-0 pb-0 mb-0"
                                    :label="$t('generic.lang_attachExcel')"/>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="pt-0 mt-0" cols="12">
                    <quill-editor v-model="form.message" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :options="quillOptions" :rules="[v => !!v]" @focus="showTouchKeyboard"></quill-editor>
                  </v-col>
                  <v-col cols="12">
                    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                                          :accept="hideTouchKeyboard"
                                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                          :options="touchKeyboard.options" class="internalWidth"/>
                    </div>
                  </v-col>

                </v-row>
              </div>

              <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right pa-1 pr-3">
                <v-spacer/>
                <v-btn class="ma-1" color="error" @click="dialog = false">
                  {{ $t('generic.lang_cancel') }}
                </v-btn>
                <v-btn :disabled="sendLoader" :loading="sendLoader" class="ma-1" color="primary" @click="send">
                  {{$t('support.lang_supportSend')}}
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-dialog>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";
import Datatable from "../../../datatable/Datatable";
import ExcelExporter from "./ExcelExporter";
import {Events} from "@/plugins/events";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';
import mixin from "@/mixins/KeyboardMixIns";

export default {
  name: "ViewOrdersComponent",
  components: {
    ExcelExporter,
    Datatable,
    quillEditor
  },
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      form: {
        emails: [],
        subject: "",
        message: "",
        excel: false,
        pdf: false
      },
      items: [],
      loading: false,
      sendLoader: false,
      order: null,
      dialog: true,
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },

      //
      activator: null,
      attach: null,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      editing: null,
      index: -1,
      menu: false,
      model: [],
      x: 0,
      search: null,
      y: 0,
    }
  },
  mounted() {
    this.loadOrder();
  },
  watch: {
    "form.emails": function (val, prev) {
      if (val.length === prev.length) return

      this.form.emails = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[Math.floor((Math.random() * 10) + this.form.emails.length)],
          }
        }

        return v
      })
    },

  }
  ,
  methods: {
    send() {
      if (!this.$refs.emailForm.validate()) return;
      this.sendLoader = true;

      let data = {};
      Object.assign(data, this.form);
      data.emails = this.form.emails.map((x) => x.text);
      data.orderID = this.$route.params.id;
      this.axios.post(ENDPOINTS.EMAILS.ITEMORDERS, data).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });

          this.dialog = false;
          this.$refs.emailForm.reset();
          this.form.message = "";
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.sendLoader = false;
      })
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
    },
    getColor(currentQTY, minQTY, standQTY) {
      if (currentQTY <= minQTY)
        return "error";
      else if (currentQTY >= minQTY && currentQTY < standQTY)
        return "warning";
      else return "success";

    },

    loadOrder() {
      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.ORDERWARE.DETAILS, {
        orderUUID: this.$route.params.id
      }).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.items = res.data.positions;
          this.order = res.data.order;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
  ,
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('erp.lang_ware_create_id'),
          value: 'ean'
        },
        {
          text: this.$t('erp.lang_orderNo'),
          value: 'number'
        },
        {
          text: this.$t('erp.lang_itemname'),
          value: 'wareName',
          sortable: false
        },
        {
          text: this.$t('erp.lang_actualStock'),
          value: 'currentQTY',
          align: "center"
        },
        {
          text: this.$t('erp.lang_warecreate_minqty'),
          value: 'minBestand'
        },
        {
          text: this.$t('erp.lang_warecreate_sollQTY'),
          value: 'sollBestand'
        },
        {
          text: this.$t('erp.lang_orderQTY'),
          value: 'orderedQTY',
          align: "center"
        },
        {
          text: this.$t('erp.lang_orderQtyVpe'),
          value: 'vpe',
          align: "center"
        }
      ];
    }
    ,
  }
}
</script>

<style>
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  top: 5px !important;
}
</style>
