<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('erp.lang_nav_wareorder') "
                :icon="icon"
                :subheading="$t('erp.lang_nav_wareorder')"
                show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <order-details-component/>
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import OrderDetailsComponent from "../../../../components/erp/storage/orderware/OrderDetailsComponent";


    export default {
        components: {
            OrderDetailsComponent,
            PageTitle,
        },
        data: () => ({
            icon: "pe-7s-box1 icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>